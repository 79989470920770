import { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import IsAdmin from "./Middlewares/IsAdmin";
import { MainContext } from "./store/main-context";
import useMainContext from "./hook/useMainContext";
const HomePage = lazy(() => import("./pages/Home"));
const HomePageAdmin = lazy(() => import("./admin/Pages/HomeAdmin"));
const ProgramEditor = lazy(() => import("./admin/Pages/Program"));
const AgbEditor = lazy(() => import("./admin/Pages/AgbEditor"));
const ImpressumEditor = lazy(() => import("./admin/Pages/ImpressumEditor"));
const DatenSchutzEditor = lazy(() => import("./admin/Pages/DatenschutzEditor"));
const GalleryEditor = lazy(() => import("./admin/Pages/GalleryEditor"));
const AboutEditor = lazy(() => import("./admin/Pages/AboutUsEditor"));
const ContactEditor = lazy(() => import("./admin/Pages/ContactEditor"));
const EventList = lazy(() => import("./admin/Pages/EventList"));
const CreateEvent = lazy(() => import("./admin/Pages/CreatEvent"));
const UsersList = lazy(() => import("./admin/Pages/UsersList"));
const UsersListAdmin = lazy(() => import("./admin/Pages/UsersListAdmin"));
const EditEvent = lazy(() => import("./admin/Pages/EditEvent"));
const DiscountCodeList = lazy(() => import("./admin/Pages/DiscountCodeList"));
const CreateDiscountCode = lazy(
  () => import("./admin/Pages/CreateDiscountCode")
);
const Purchases = lazy(() => import("./admin/Pages/Purchases"));
const EditDiscountCode = lazy(() => import("./admin/Pages/EditDiscountCode"));
const Login = lazy(() => import("./admin/Pages/Login"));
const Dashboard = lazy(() => import("./admin/Pages/Dashboard"));
const Program = lazy(() => import("./pages/Program"));
const Gallery = lazy(() => import("./pages/Gallery"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Booking = lazy(() => import("./pages/Booking"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Agb = lazy(() => import("./pages/AGB"));
const DatenSchutz = lazy(() => import("./pages/Datenschutz"));
const Tickets = lazy(() => import("./pages/Tickets"));
const Navbar = lazy(() => import("./components/common/shared/Navbar"));
const Footer = lazy(() => import("./components/common/shared/Footer"));
const Loader = lazy(() => import("./components/common/Loader"));
const NotFound = lazy(() => import("./pages/NotFound"));
const UserLogin = lazy(() => import("./pages/Login"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));
const ReviewsList = lazy(() => import("./admin/Pages/ReviewsList"));
const Thankyou = lazy(() => import("./pages/Thankyou"));
const BillingForm = lazy(() => import("./pages/BillingForm"));

function App() {
  const mainContext = useMainContext();

  // useEffect(() => {
  //   i18n.changeLanguage("de");

  //   localStorage.setItem("lang", "de");
  // }, []);
  const unProctectedRoutes = () => {
    return (
      <>
        <Route path="/" element={<HomePage />} />
        <Route path="/program" element={<Program />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/booking/:id" element={<Booking />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/agb" element={<Agb />} />
        <Route path="/datenschutz" element={<DatenSchutz />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/success" element={<Thankyou />} />
        <Route path="/booking/:id/billing" element={<BillingForm />} />

        <Route path="*" element={<NotFound />} />
      </>
    );
  };
  const proctectedRoutes = () => {
    return (
      <>
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route
          path="/admin/dashboard/home-editor"
          element={<HomePageAdmin />}
        />
        <Route path="/admin/dashboard/users" element={<UsersList />} />{" "}
        <Route path="/admin/dashboard/admins" element={<UsersListAdmin />} />
        <Route
          path="/admin/dashboard/program-editor"
          element={<ProgramEditor />}
        />
        <Route path="/admin/dashboard/agb-editor" element={<AgbEditor />} />
        <Route
          path="/admin/dashboard/datenschutz-editor"
          element={<DatenSchutzEditor />}
        />
        <Route
          path="/admin/dashboard/impressum-editor"
          element={<ImpressumEditor />}
        />
        <Route
          path="/admin/dashboard/gallery-editor"
          element={<GalleryEditor />}
        />
        <Route
          path="/admin/dashboard/about-us-editor"
          element={<AboutEditor />}
        />
        <Route
          path="/admin/dashboard/contact-editor"
          element={<ContactEditor />}
        />
        <Route path="/admin/dashboard/events" element={<EventList />} />
        <Route path="/admin/dashboard/events/:id" element={<EditEvent />} />
        <Route
          path="/admin/dashboard/events/create"
          element={<CreateEvent />}
        />
        <Route
          path="/admin/dashboard/events/create"
          element={<CreateEvent />}
        />
        <Route
          path="/admin/dashboard/discount-list"
          element={<DiscountCodeList />}
        />
        <Route
          path="/admin/dashboard/discount-list/create"
          element={<CreateDiscountCode />}
        />
        <Route
          path="/admin/dashboard/discount-list/:id"
          element={<EditDiscountCode />}
        />
        <Route path="/admin/dashboard/purchases" element={<Purchases />} />
        <Route path="/admin/dashboard/reviews" element={<ReviewsList />} />
      </>
    );
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <MainContext.Provider value={{ ...mainContext }}>
          <Navbar />
          <IsAdmin>
            <Routes>
              {proctectedRoutes()}
              {unProctectedRoutes()}
            </Routes>
          </IsAdmin>
          <Footer />
        </MainContext.Provider>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
